export const freeFeatures = {
  available: [
    "24x7x365 support desk",
    "Proactive maintenence",
    "Access to our expert team",
    "Unlimited usage",
  ],
  blocked: [
    "Fees for third party SaaS services"
  ],
};

export const premiumFeatures = {
  available: [
    "Data engineering",
    "Data analysis",
    "DAta science",
    "Software engineering",
    "Strategic advisory"
  ],
  blocked: [
  ],
};
