import classNames from "classnames";
import React from "react";
import { freeFeatures, premiumFeatures } from "../../constants/pricing";
import PriceCard from "./PriceCard";

interface IPricingListProps {
  isMonthly: boolean;
}

const PricingList: React.FC<IPricingListProps> = ({ isMonthly }) => {
  const type = isMonthly ? "month" : "year";


  return (
    <div
      className={classNames(
        "lg:pb-[-6px] flex justify-center flex-col lg:flex-row items-center lg:items-stretch px-6 py-10 lg:pt-0 gap-6 relative z-10 lg:top-[-87px]",
      )}
    >
      <PriceCard
        name="Managed Service"
        description="We run and manage your ClickHouse cloud data warehouse and integrated suite of tools."
        price={1}
        type={type}
        href="/contact"
        features={freeFeatures}
        label="Get Started Now"
        isFree
      />

      <PriceCard
        price={1}
        name={"Professional Services"}
        label="Get Started Now"
        href="/contact"
        description="Data Engineering services to configure and develop your data platform and engineering."
        type={type}
        features={premiumFeatures}
      />
    </div>
  );
};

export default PricingList;
