import classNames from "classnames";
import React from "react";

import { CheckGradientIcon, CloseIcon, StarIcon } from "../../images/icons";
import Button from "../UI/Button";

interface IPriceCardProps {
  price: number;
  name: string;
  description: string;
  label: string;
  onClick: () => void;
  isFree?: boolean;
  href?: string;
  type: "month" | "year";
  features: {
    available: string[];
    blocked: string[];
  };
}

const PriceCard: React.FC<IPriceCardProps> = ({
  isFree,
  name,
  price,
  description,
  type,
  features,
  label, 
  onClick,
  href
}) => {
  const formattedPrice = new Intl.NumberFormat("en-US", {
    currency: "USD",
  }).format(price);

  return (
    <div
      className={classNames(
        "w-full max-w-[378px] flex flex-col justify-between p-6 pt-10 lg:py-10 bg-gradient-to-r from-red-600 to-pink-600 text-white rounded-3xl",
        {
          "!from-white !to-white border border-grey-100 !text-black-light":
            isFree,
        }
      )}
    >
      <div>
        <div className="flex items-center justify-center gap-3 pb-[11px]">
          {!isFree && (
            <i className="w-8 h-8 rounded-full border border-white flex items-center justify-center">
              <StarIcon className="text-white w-[11px] h-[11px]" />
            </i>
          )}
          <h4 className="text-xl font-semibold">{name}</h4>
        </div>
        <p
          className={classNames("text-grey-lite text-sm text-center", {
            "!text-white": !isFree,
          })}
        >
          {description}
        </p>
      </div>
      <div>
        <span className="text-center text-[56px] block py-4 font-semibold">
          ${formattedPrice}
          <span
            className={classNames("text-base text-grey-lite font-normal", {
              "!text-white": !isFree,
            })}
          >
            {" "}
            / {type === "month" ? "Month" : "Year"}
          </span>
        </span>
        <Button
          color={isFree ? "black" : "white"}
          label={label}
          to={href}
          onClick={onClick}
          className="!w-full"
        />
        <ul className="p-0 list-none space-y-4 pt-8">
          {features.available.map((feature) => (
            <li key={feature} className="flex gap-4">
              <i className="max-w-[28px] w-full">
                <CheckGradientIcon
                  fillColor={isFree ? "url(#gradientColor)" : "#FFFFFF"}
                  iconColor={isFree ? "#FFFFFF" : "url(#gradientColor)"}
                />
              </i>
              <span className="block pt-0.5">{feature}</span>
            </li>
          ))}
          {features.blocked.map((feature) => (
            <li key={feature} className="flex gap-4">
              <i className="max-w-[28px] w-full">
                <CloseIcon
                  fillColor={isFree ? "#F5F6FA" : "rgba(255, 255, 255, 0.2)"}
                  iconColor={isFree ? "#667085" : "#FFFFFF"}
                />
              </i>
              <span
                className={classNames("block pt-0.5 opacity-50", {
                  "!text-grey-lite !opacity-100": isFree,
                })}
              >
                {feature}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PriceCard;
